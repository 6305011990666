/* Core imports */
/* Basic Layouting */
:root {
  --background: #23262e;
  --text: #d5ced9;
  --pink: #f0a;
  --yellow: #ffe66d;
  --green: #96e072;
  --link: #48eddd;
  --link-hover: #8a2be2;
  --brand-1: #2f77c8;
  --brand-2: #ad1a1a;
  --brand-3: #ffae16;
  --brand-4: #79b6e2;
  --neutral-00: #fff;
  --neutral-10: #f5f7fa;
  --neutral-20: #e9ecf1;
  --neutral-30: #dde0e6;
  --neutral-40: #c1c9d4;
  --neutral-50: #98a1ad;
  --neutral-60: #767f8a;
  --neutral-70: #4c5969;
  --neutral-80: #303b47;
  --neutral-100: #000;
  --texture-1: #fff9ee;
  --texture-2: #f5efe6;
  --error: #dd3636;
  --reduced-price: #ad1a1a;
  --fatal: #0c1c2e;
  --idle: #e9ecf1;
  --info: #bfc9d5;
  --notification: #6fc4ff;
  --positive: #91c11e;
}
@font-face {
  font-display: fallback;
  font-family: SourceCodePro;
  font-weight: 400;
  src: url("../../../public/assets/fonts/SourceCodePro-Regular.ttf") format('truetype');
}
@font-face {
  font-display: fallback;
  font-family: SourceCodePro;
  font-weight: 500;
  src: url("../../../public/assets/fonts/SourceCodePro-Black.ttf") format('truetype');
}
html {
  font-size: 62.5%;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-wrapper {
  background: var(--background);
  max-width: 100vw;
  margin: 0 auto;
  color: var(--text);
  font-family: var(--font-family-regular);
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
main {
  margin-top: 6rem;
  padding: 0 5%;
}
@media (min-width: 769px) {
  main {
    padding: 0 10%;
  }
}
b,
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
p {
  display: block;
  margin-block-start: 0.5rem;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
* {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
button,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
html {
  font-size: 10px;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquotebefore,
blockquoteafter,
qbefore,
qafter {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  background: inherit;
}
:root {
  --font-family-regular: SourceCodePro, sans-monospace;
  --font-size-xsmall: 2rem;
  --line-height-xsmall: 2.08;
  --font-size-small: 2.8rem;
  --line-height-small: 1.92;
  --font-size-medium: 4.4rem;
  --line-height-medium: 1.84;
  --font-size-large: 5.2rem;
  --line-height-large: 1.2;
  --font-size-xlarge: 8rem;
  --line-height-xlarge: 1.6;
  --font-size-xxlarge: 12.8rem;
  --line-height-xxlarge: 1.6;
  --space-small: 1.6rem;
  --space-medium: 4.8rem;
  --space-large: 8rem;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
}
:root {
  --elevation-s: 0 0 2px 0 rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.15);
  --elevation-m: 0 2px 5px -1px rgba(0,0,0,0.15), 0 1px 3px 0 rgba(0,0,0,0.08);
  --elevation-l: 0 3px 8px -1px rgba(0,0,0,0.15), 0 1px 3px 0 rgba(0,0,0,0.05);
  --elevation-xl: 0 8px 20px -3px rgba(0,0,0,0.12), 0 2px 5px 0 rgba(0,0,0,0.07);
  --elevation-xxl: 0 12px 40px -8px rgba(0,0,0,0.12), 0 5px 10px 0 rgba(0,0,0,0.07);
}
:root {
  --z-index-lowest: 1;
  --z-index-low: 10;
  --z-index-medium: 50;
  --z-index-overlay: 60;
  --z-index-high: 70;
  --z-index-highest: 100;
}
.test500 {
  background: #f00;
  font-family: SourceCodePro-Regular;
}
.test600 {
  background: #00f;
}
.header {
  margin: 0;
  padding: 0 5%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: top 0.5s;
  max-width: calc(100vw);
  z-index: var(--z-index-highest);
  background: var(--background);
}
@media (min-width: 769px) {
  .header {
    padding: 0 10%;
  }
}
.header__content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
}
.footer {
  margin: 0;
  padding: 0 5%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: calc(100vw);
  z-index: var(--z-index-highest);
  display: flex;
  justify-content: flex-end;
  background: var(--background);
}
@media (min-width: 769px) {
  .footer {
    padding: 0 10%;
  }
}
.footer__link {
  font-size: 1.2rem;
  color: var(--link);
}
.footer__link:hover {
  color: var(--link-hover);
  text-decoration: underline;
  text-decoration-style: wavy;
}
.footer__content-wrapper {
  padding: 2.4rem 0;
  display: flex;
  align-items: center;
}
.logo__content-wrapper p {
  text-transform: uppercase;
  font-size: var(--font-size-xsmall);
  font-weight: var(--font-weight-regular);
  line-height: 1;
}
.logo__content-wrapper p:first-child {
  letter-spacing: 0.2rem;
}
.logo__content-wrapper p:last-child {
  letter-spacing: 0.4rem;
}
.section-frame {
  height: auto;
  padding-bottom: var(--space-large);
}
.section-frame__content-wrapper p {
  padding: var(--space-small) 0;
}
.section-frame__pink {
  color: var(--pink);
}
.section-frame__yellow {
  color: var(--yellow);
}
.section-frame__green {
  color: var(--green);
}
.section-frame p {
  padding: var(--space-small) 0 var(--space-medium) 0;
  line-height: 3.3rem;
}
.about p {
  padding: 0;
}
.about__keywords {
  color: var(--yellow);
}
.about__keywords__heading {
  color: var(--green);
}
.about ul,
ol {
  display: block;
  margin-block-start: 0.5rem;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.skills__list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-large);
}
.skills__list li {
  width: 7rem;
}
.skills__list img {
  width: 100%;
  height: auto;
}
.skills__list ul,
ol {
  display: block;
  margin-block-start: 0.5rem;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.work-experience__title {
  font-size: var(--font-size-xsmall);
}
.work-experience__link {
  color: var(--link);
}
.work-experience__link:hover {
  color: var(--link-hover);
  text-decoration: underline;
  text-decoration-style: wavy;
}
.work-experience__img-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--space-small);
  width: 100%;
  padding: 0 3rem;
}
.work-experience__img-wrapper img {
  height: 5rem;
  margin: var(--space-small);
  transition: transform 0.3s ease-in-out;
}
.work-experience__img-wrapper img:hover {
  transform: scale(1.1);
}
.white-logo {
  filter: invert(100%);
}
.contact {
  height: 100vh;
}
.contact__link {
  color: var(--link);
}
.contact__link:hover {
  color: var(--link-hover);
  text-decoration: underline;
  text-decoration-style: wavy;
}
.projects-overview {
  width: 100%;
}
.projects-overview__wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--space-small);
}
.projects-overview__thumbnail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--text);
  margin: 1rem;
}
@media (min-width: 500px) {
  .projects-overview__thumbnail {
    width: 45%;
    padding-top: var(--space-small);
  }
}
@media (min-width: 1200px) {
  .projects-overview__thumbnail {
    width: 30%;
    padding-top: var(--space-small);
  }
}
.projects-overview__icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
.projects-overview__icons a {
  width: 2rem;
  margin: 0 1rem;
}
.projects-overview__thumbnail h3 {
  cursor: pointer;
  color: var(--link);
}
.projects-overview__thumbnail h3:hover {
  color: var(--link-hover);
  text-decoration: underline;
  text-decoration-style: wavy;
}
.projects-overview__description-short {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 1rem;
}
.projects-overview__description-long {
  color: var(--background);
  height: fit-content;
}
.projects-overview__thumbnail > img {
  cursor: pointer;
  width: auto;
  height: 14rem;
  padding-top: 2rem;
}
@media (min-width: 500px) {
  .projects-overview__thumbnail > img {
    padding-bottom: var(--space-small);
  }
}
.projects-overview__thumbnail img:hover {
  transform: scale(1.1);
}
strong {
  font-weight: bold;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5); /* Hintergrund mit Transparenz für den Modal-Overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modal__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.6rem;
  color: var(--background);
}
.modal__content {
  background-color: #fff;
  padding: 1.6rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Schatten für das Modal */
  width: 100%;
  max-height: 80%;
  overflow: auto;
}
@media (min-width: 769px) {
  .modal__content {
    width: 80%;
  }
}
.modal button {
  padding: 0 0.8rem;
  background-color: var(--background);
  color: var(--text);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
}
.modal button:hover {
  background-color: #0056b3;
  color: var(--text);
}
.modal button:focus {
  outline: none;
}
.welcome p {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
  line-height: 5.5rem;
}
.welcome__keywords {
  color: var(--yellow);
}
.hamburger-menu {
  display: block;
  position: relative;
  top: 0;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}
.hamburger-menu a {
  text-decoration: none;
  transition: color 0.3s ease;
}
.hamburger-menu a:hover {
  text-decoration: underline;
  text-decoration-style: wavy;
}
.hamburger-menu input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}
.hamburger-menu span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: var(--text);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
.hamburger-menu span:first-child {
  transform-origin: 0% 0%;
}
.hamburger-menu span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
.hamburger-menu input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: var(--text);
}
.hamburger-menu input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.hamburger-menu input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}
.hamburger-menu__menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  padding-top: 125px;
  background: var(--background);
  list-style-type: none;
  -webkit-font-smoothing: antialiased; /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.hamburger-menu__menu li {
  padding: 10px 0;
  font-size: 22px;
  color: var(--link);
  cursor: pointer;
}
.hamburger-menu__menu li:hover {
  color: var(--link-hover);
  text-decoration: underline;
  text-decoration-style: wavy;
}
.hamburger-menu input:checked ~ ul {
  transform: none;
}
/* The entry file for compiling all Stylus files */
